// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
body, html {
    background: $white;
    font-family: "Lato";
}

html {
    scroll-behavior: smooth;
}

.background-header {
    filter: grayscale(0.75);
    min-height: 230px;
    background: url("/css/images/Omslag.jpg") no-repeat center;
    background-size: cover;

    div {
        position: absolute;
        top: 60%;
        left: 40%;
        transform: translate(-50%, -50%);
    }
}

.header-text {
    background: rgba(1, 1, 1, 0.5);
}

.navbar-toggler {
    border: none;
    outline: none !important;
}

.navbar-nav {
    font-size: 1.1rem;
}

.navbar-brand {
    img {
        max-height: 50px;
    }
}

.article-box {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-1 {
    z-index: 1;
}

ul {
    padding-left: 10px;
}

input, textarea, .btn, .card {
    border-radius: 0 !important;
}

section {
    padding-top: 50px;
    padding-bottom: 50px;
}

h2 {
    margin-bottom: 40px;
}

.card {
    margin-top: 20px;
    margin-bottom: 10px;
}

.container {
    max-width: 960px;
}

.list-fa {
    margin-left: 30px;

    li {
        position: relative;
        margin-bottom: 5px;
        font-size: 1.1rem;

        .fas {
            position: absolute;
            margin-left: -30px;
            margin-top: 5px;
        }
    }
}

.btn-images {
    font-weight: 700;
}

.bg-shadow {
    background: #f8f8f8;
}

.google-map {

    @include media-breakpoint-down(sm) {
        padding: 0;
        margin-bottom: -5px;
    }

    iframe {
        width: 100%;
        height: 350px;
    }
}

@include media-breakpoint-up(lg) {
    section.container {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }
}

@include media-breakpoint-down(md) {
    .navbar-dark {
        background: #181818;
    }

    .container-padding {
        padding: 25px;
    }
}

@include media-breakpoint-down(sm) {

    .images-container, .arrangements-container {
        display: flex;

        .row {
            max-width: 300px;
            margin: 0 auto;
        }
    }


    .images-container {
        .row {
            max-height: 500px;
            transition: max-height 0.15s ease-out;
            overflow: hidden;

            &.show {
                max-height: 5000px;
                transition: max-height 0.25s ease-in;
            }
        }
    }

}

@include media-breakpoint-up(lg) {
    body, html {
        height: 100%;
    }

    .background-header {
        filter: grayscale(0.75);
        width: 100%;
        height: 100%;
        background: url("/css/images/Omslag.jpg") no-repeat center fixed;
        background-size: cover;
    }

    .navbar {
        position: absolute;
    }
}
